import axios from "axios";

export const topAdvert = async (property_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-adverts/top-advert/${property_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const getDeletedAdverts = async (property_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/deleted-adverts/deleted-adverts/${property_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const updateAdvert = async (property_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-adverts/update-advert/${property_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};

export const deleteAdvert = async (property_id) => {
  try {
    const { data } = await axios.get(
      `${process.env.VUE_APP_STRAPI_API_URL}/user-adverts/delete-advert/${property_id}`,
      {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("jwt")}`,
        },
      }
    );
    return data;
  } catch (e) {
    return e;
  }
};
