<template>
  <div
    class="fixed z-10 inset-0 overflow-y-auto"
    aria-labelledby="modal-title"
    role="dialog"
    aria-modal="true"
  >
    <div
      class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    >
      <div
        class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
        aria-hidden="true"
      ></div>
      <span
        class="hidden sm:inline-block sm:align-middle sm:h-screen"
        aria-hidden="true"
        >&#8203;</span
      >
      <div
        class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
      >
        <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
          <div class="sm:flex sm:items-start">
            <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
              <h3
                class="text-md leading-6 font-bold text-body-1 select-none"
                id="modal-title"
              >
                Seznam inzertních portálů
              </h3>
              <div>
                <div class="grid grid-cols-4 mt-6 font-medium select-none">
                  <div class="text-left pr-12 pb-4">sreality.cz</div>
                  <div class="text-left pr-12 pb-4">idnes.cz</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
          <button
            @click="closeModal()"
            type="button"
            class="mt-3 focus:outline-none w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          >
            Zavřít
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdvertisingModal",
  methods: {
    closeModal() {
      this.$emit("modalOpenHandler");
    },
  },
};
</script>
