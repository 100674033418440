<template>
  <div class="absolute z-50">
    <div>
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            style="color: #1b26e2"
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4">
              <div>
                <div class="text-left pb-2">
                  <h3
                    class="text-md leading-6 font-bold select-none pt-2 text-left pb-4"
                    id="modal-title"
                  >
                    <p>Aktualizace nemovitosti</p>
                  </h3>
                </div>

                <div v-if="updatedProperty">
                  <div
                    v-for="(value, name, idx) in updatedProperty"
                    :key="idx"
                    :class="[idx === 0 || idx === 1 ? '' : 'pt-3']"
                  >
                    <span class="font-semibold">{{ nameHandler(name) }}</span>
                    <textarea
                      v-if="name !== 'id' && name !== 'propertyPrice'"
                      :rows="4"
                      type="text"
                      style="color: #1b26e2"
                      :class="inputCSS"
                      v-model="updatedProperty[name]"
                    />
                  </div>
                </div>

                <div v-if="updatedProperty">
                  <span class="font-semibold">Cena nemovitosti</span>
                  <input
                    v-mask="'###########'"
                    v-model="updatedProperty.propertyPrice"
                    :class="inputCSS"
                    type="number"
                  />
                </div>

                <div class="flex flex-row-reverse justify-between pt-4">
                  <button
                    @click="removeAdvertHandler()"
                    style="background-color: #1b26e2"
                    type="button"
                    :class="[btnCss, 'hover:bg-blue-500']"
                  >
                    Aktualizovat
                  </button>
                  <button
                    @click="$emit('close')"
                    type="button"
                    :class="[btnCss, 'bg-gray-400 hover:bg-gray-300']"
                  >
                    Zpět
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { updateAdvert } from "../../services/rest/advert-services";
import { mapState } from "vuex";
export default {
  name: "RemoveAdvertModal",
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },
  data: () => {
    return {
      err: "",
      inputCSS:
        "w-full mt-3 px-4 py-2 border border-gray-300 rounded-md text-gray-800 focus:outline-none focus:border-body-1 focus:bg-body-1 focus:bg-opacity-5",
      btnCss:
        "rounded-md border border-transparent shadow-sm px-4 py-1 text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400",
      updatedProperty: {},
    };
  },
  mounted() {
    console.log(this.currentProperty);
    const {
      propertyDescription,
      surroundingDescription,
      outsideViewDescription,
      energyAndAdditionalDescription,
      propertyPrice,
    } = this.currentProperty;

    this.updatedProperty = {
      id: this.currentProperty.id,
      propertyDescription,
      surroundingDescription,
      outsideViewDescription,
      energyAndAdditionalDescription,
      propertyPrice,
    };
  },
  methods: {
    async removeAdvertHandler() {
      console.log(this.updatedProperty);
      await updateAdvert(this.updatedProperty.id).then((res) => {
        console.log(res);
      });
      setTimeout(() => {
        this.$emit("close");
      }, 3500);
    },
    nameHandler(name) {
      if (Object.keys(this.updatedProperty).includes(name)) {
        if (name === "propertyDescription") return "Stav a dispozice";
        if (name === "surroundingDescription")
          return "Okolí a občanská vybavenost";
        if (name === "outsideViewDescription")
          return "Konstrukce a technický stav";
        if (name === "energyAndAdditionalDescription")
          return "Energetické a doplňující informace";
      }
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
