<template>
  <div>
    <section>
      <div class="max-w-4xl mx-auto mt-6">
        <div class="text-center mb-12 text-2xl text-body-1 font-semibold">
          Inzerce
        </div>

        <div class="flex flex-col gap-5 mx-8 p-6 rounded-md bg-body-1 bg-opacity-5 text-body-4 text-17">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <h2 class="font-bold">Základní inzerce</h2>
            <h2 class="text-left md:text-right font-bold">Zdarma</h2>
          </div>
          <div class="flex flex-col gap-3 md:flex-row md:gap-0 md:justify-between">
            <router-link 
              class="bg-body-1 text-white px-7 py-3 rounded-lg w-fit" 
              :to="propertyPreviewLink"
            >
              Vaše nemovitost
            </router-link>
            <a 
              class="bg-body-1 text-white px-7 py-3 rounded-lg w-fit" 
              :href="facebookPropertyPreviewLink" 
              target="_blank"
            >
              Sdílet na Facebook
            </a>
          </div>
        </div>

        <div class="mx-8 p-6 rounded-md mt-12" :class="
          advertArray.length
            ? 'bg-green-200 bg-opacity-25'
            : 'bg-body-1 bg-opacity-5'
        ">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="">
              <h2 class="text-17 text-body-4 font-bold">Placená inzerce na realitních serverech</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p v-if="!basicAdvertising.payd">
                  {{ basicAdvertising.price }} Kč
                </p>
                <p class="text-body-1 text-xl" v-if="basicAdvertising.payd">
                  ✔
                </p>
              </h2>
            </div>
          </div>
          <div class="mt-6">
            <p class="text-17 text-body-4">
              Základní balíček zajistí publikování inzerce na významných
              realitních serverech. Váš inzerát na prodej nemovitosti zde bude
              zveřejněn po dobu jednoho měsíce. Balíček můžete využít pouze
              jednou, pouze na jednu nemovitost. Máte ji ovšem k dispozici zcela
              zdarma.
              <button type="button" @click="modalOpenHandler()"
                class="modal-open font-bold text-body-1 hover:opacity-80 duration-300 focus:outline-none">
                Zobrazit inzertní portály
              </button>
            </p>
          </div>
          <div class="mt-6">
            <div class="mt-2 flex flex-row items-center">
              <div v-if="basicAdvertising.payd">
                <button v-if="!advertInDatabase"
                  class="create-advertise"
                  @click="portalModal()">
                  Vytvořit inzerát
                </button>
              </div>

              <div v-if="!basicAdvertising.payd">
                <ButtonSpinnerStripe :stripe-price-id="basicAdvertising.stripePriceId" />
              </div>

              <button @click="updatePropertyHandler()"
                class="text-body-1 font-semibold border-body-1 hover:bg-blue-100 duration-300 bg-white h-8 px-4 text-xs rounded-md focus:outline-none border-2"
                v-if="advertInDatabase && advertArray && !isLoading">
                {{ updatedMessage }}
              </button>
              <div v-if="isLoading"
                class="flex items-center flex-row text-white font-semibold border-body-1 bg-blue-700 duration-300 bg-white h-8 px-4 text-xs rounded-md focus:outline-none border-2">
                <span class="pr-2">Aktualizujeme</span>
                <Loading />
              </div>
              <button @click="openRemoveModalHandler()"
                class="ml-2 text-body-1 font-semibold border-body-1 hover:bg-blue-100 duration-300 bg-white h-8 px-4 text-xs rounded-md focus:outline-none border-2"
                v-if="advertInDatabase && advertArray">
                Odstranit
              </button>
            </div>
            <div>
              <div class="pt-4 flex" v-if="!advertInDatabase">
                <div>
                  <Info />
                </div>
                <span style="line-height: 24px" class="text-gray-500 text-md text-body-1 select-none pl-2">Pro vložení
                  inzerátu zdarma musí být nahrány min. 4 fotky a
                  max. 8 fotek.</span>
              </div>

              <div class="pt-2 pb-2.5" v-if="!allDataFetched">
                <img src="../../../public/images/dataLoading.gif" />
              </div>
            </div>
            <div v-if="advertInDatabase && advertArray" class="p-4 border-2 border-body-1 rounded-md mt-8">
              <div>
                <div class="grid grid-cols-3 text-sm text-body-1 font-bold">
                  <div>Číslo inzerátu</div>
                  <div class="text-center">Inzertní portál</div>
                  <div class="text-right">Odkaz inzerátu</div>
                </div>
              </div>

              <div v-for="advert in advertArray" :key="advert.advert_id">
                <div class="grid grid-cols-3 text-md text-body-1 pt-2">
                  <div>{{ advert.advert_id }}</div>
                  <div class="text-center">{{ advert.portal }}</div>
                  <div class="text-right font-semibold">
                    <a v-if="generatingUrlLink" target="_blank"
                      :href="`${advert.advert_url}?preview=1#img=0&fullscreen=false`">Přejít na inzerát</a>
                    <div v-if="!generatingUrlLink">generuji odkaz...</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mx-8 bg-body-1 bg-opacity-5 p-6 rounded-md mt-12">
          <div class="grid grid-cols-1 md:grid-cols-2">
            <div class="">
              <h2 class="text-17 text-body-4 font-bold">Topování</h2>
            </div>
            <div class="text-left md:text-right">
              <h2 class="text-17 text-body-4 font-bold">
                <p class="text-body-1 text-xl" v-if="topping.payd">✔</p>
                <p v-if="!topping.payd">{{ topping.price }} Kč</p>
              </h2>
            </div>
          </div>

          <div class="mt-6">
            <p class="text-17 text-body-4">
              Topování posune vaši nemovitost na přední příčky ve výpisu na
              inzertních portálech.
            </p>
            <div class="flex mt-4">
              <div v-if="!advertInDatabase">
                <Info />
              </div>
              <span v-if="!advertInDatabase" style="line-height: 24px"
                class="text-gray-500 text-md text-body-1 select-none pl-2">Topování doporučujeme provádět jednou týdně
                ideálně v
                dopoledních hodinách.
              </span>
            </div>
          </div>

          <div class="mt-6">
            <div v-if="allDataFetched">
              <div class="inline-flex">
                <div v-if="topping.payedAgain">
                  <ButtonSpinnerStripe :stripe-price-id="topping.stripePriceId" />
                </div>
                <div v-if="!topping.payd">
                  <div v-if="advertInDatabase && advertArray && !canTopAdvert">
                    <ButtonSpinnerStripe :stripe-price-id="topping.stripePriceId" />
                  </div>
                  <div v-else class="flex flex-col">
                    <div class="flex flex-row">
                      <div v-if="!advertInDatabase">
                        <Info />
                      </div>
                      <span v-if="!advertInDatabase" style="line-height: 24px"
                        class="text-gray-500 text-md text-body-1 select-none pl-2">Nemáte žádný inzerát.
                      </span>
                    </div>
                    <div class="flex flex-row">
                      <div v-if="!canTopAdvert">
                        <Info />
                      </div>
                      <span v-if="!canTopAdvert" style="line-height: 24px"
                        class="text-gray-500 text-md text-body-1 select-none pl-2">Inzeráty můžete topovat až den po
                        vložení.</span>
                    </div>
                  </div>
                </div>

                <div class="mr-4 hover:opacity-70" v-if="topping.payd">
                  <button v-if="
                    (advertInDatabase && advertArray && canTopAdvert) ||
                    nextTopAvailable
                  " style="line-height: 40px" @click="topEvtHandler()"
                    class="modal-open my-2 font-semibold px-4 bg-white h-12 text-body-1 rounded-md focus:outline-none border-2 border-body-1 mt-8 md:mt-0">
                    <img v-if="topLoading" src="/images/1496.gif" class="inline-flex mr-4" />
                    <img v-if="!topLoading" src="/images/activity (1) 1.png" class="inline-flex mr-4" />Topovat
                  </button>

                  <div v-if="!canTopAdvert" class="flex">
                    <div>
                      <Info />
                    </div>
                    <span style="line-height: 24px" class="text-gray-500 text-md text-body-1 select-none pl-2">Inzeráty
                      můžete topovat až den po vložení.</span>
                  </div>

                  <div v-if="!advertInDatabase" class="flex">
                    <div>
                      <Info />
                    </div>
                    <span style="line-height: 24px" class="text-gray-500 text-md text-body-1 select-none pl-2">Nemáte
                      žádný inzerát.</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-2 pb-2.5" v-if="!allDataFetched">
              <img src="../../../public/images/dataLoading.gif" />
            </div>
            <!-- Bad response from top advert  -->
            <div v-if="topErr && topStatArr.length > 0" class="mt-6">
              <p class="text-red-600 font-semibold">
                {{ topErrMsg.idnes }}
              </p>
              <p class="text-red-600 font-semibold">
                {{ topErrMsg.sreality }}
              </p>
            </div>
          </div>
        </div>

        <div class="grid grid-cols-1 md:grid-cols-2 mt-12 mx-8 mb-24">
          <div class="hover:opacity-50 duration-300">
            <button class="px-8 text-body-3 border border-body-3 font-bold text-15 focus:outline-none rounded-md h-14"
              @click="$router.go(-1)">
              Krok zpět
            </button>
          </div>
          <div class="text-left md:text-right hover:opacity-80 mt-8 md:mt-0 duration-300">
            <button class="px-8 font-bold text-15 bg-body-3 text-white focus:outline-none rounded-md h-14"
              @click="save()">
              Pokračovat
            </button>
          </div>
        </div>
      </div>
    </section>
    <AdvertisingModal v-if="modalOpen" v-on:modalOpenHandler="modalOpenHandler($event)" />
    <!-- PortalModal -->
    <div v-if="portalOpen" class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog"
      aria-modal="true">
      <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div
          class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                <h3 class="text-md leading-6 font-bold text-body-1 select-none" id="modal-title">
                  {{ modalText }}
                </h3>
              </div>
            </div>
          </div>
          <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex"
            :class="modalText === 'Vaše nemovitost má meně než 4 fotky!' || modalText === 'Vaše nemovitost má více než 8 fotek!' ? 'sm:flex-row' : ' sm:flex-row-reverse justify-between'">
            <button @click="addToPortal(portalName)" type="button"
              class="mt-3 focus:outline-none w-full inline-flex rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm"
              :class="{
                hidden: modalText === 'Vaše nemovitost má meně než 4 fotky!' ||
                  modalText === 'Vaše nemovitost má více než 8 fotek!'
              }">
              Ano
            </button>
            <button @click="
              [
                (portalOpen = !portalOpen),
                (advertSrealityPass = false),
                (advertIdnesPass = false),
              ]
            " type="button"
              class="mt-3 focus:outline-none w-full inline-flex rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 sm:mt-0 sm:w-auto sm:text-sm">
              Zrušit
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- addAdvertPassed -->
    <div v-if="addAdvertPassed && !advertInDatabase">
      <div class="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
        <div class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div
            class="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-center sm:mt-0 sm:ml-4 sm:text-left pb-2">
                  <h3 class="text-xl leading-6 font-bold text-body-1 select-none py-4 text-center" id="modal-title">
                    Přidáváme inzerát na inzertní portál {{ portalName }}
                  </h3>
                  <div class="flex justify-center">
                    <div></div>
                    <div>
                      <img src="../../../public/images/dataLoading.gif" />
                    </div>
                    <div></div>
                  </div>
                  <div class="flex justify-center">
                    <div></div>
                    <div class="text-lg text-body-1 pt-2">
                      {{ this.workingOn }}
                    </div>
                    <div></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Advert remove -->
    <RemoveAdvertModal v-if="removeModalOpen" :property="currentProperty" v-on:update-data="updateDataHandler($event)"
      v-on:close="removeModalOpen = false" />
    <UpdateAdvertModal v-if="updateModalOpen" v-on:close="updateModalOpen = false" />
  </div>
</template>

<script>
import { mapState } from "vuex";
import ButtonSpinnerStripe from "@/components/buttons/ButtonSpinnerStripe";
import AdvertisingModal from "@/components/advertising/AdvertisingModal";
import {
  getAdverts,
  getStripe,
  getTopAdverts,
} from "../../services/rest/services";
import {
  topAdvert,
  getDeletedAdverts,
  updateAdvert,
} from "@/services/rest/advert-services";
import axios from "axios";
import Info from "../../components/svg/Info.vue";
import RemoveAdvertModal from "../../components/modals/RemoveAdvertModal.vue";
import UpdateAdvertModal from "../../components/modals/UpdateAdvertModal.vue";
import Loading from "../../components/svg/Loading.vue";
import { IS_BETA, IS_LOCALHOST } from "../../globalConstants";

export default {
  components: {
    ButtonSpinnerStripe,
    AdvertisingModal,
    Info,
    Loading,
    RemoveAdvertModal,
    UpdateAdvertModal,
  },
  computed: {
    ...mapState("main", ["currentProperty"]),
    ...mapState("user", ["user"]),
  },
  data() {
    return {
      canTopAdvert: false,
      topErrMsg: {
        idnes: "",
        sreality: "",
      },
      deletedAdvertsArray: [],
      removeModalOpen: false,
      updateModalOpen: false,
      topErr: false,
      topStatArr: [],
      topLoading: false,
      generatingUrlLink: false,
      workingOn: "",
      workingOnTime: 0,
      modalText: "Přejete si přidat nemovitost na inzertní portály?",
      advertIdnesPass: false,
      advertSrealityPass: false,
      preventAdvert: true,
      advertArray: [],
      topping: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1Lto8fI4HEbZGFYD04kc6HL5"
          : "price_1LhUTpI4HEbZGFYDr39WtsST",
        price: 100,
        payd: false,
        payedAgain: false,
      },
      basicAdvertising: {
        stripePriceId: IS_BETA || IS_LOCALHOST
          ? "price_1LtnP1I4HEbZGFYDZ2gIUrtx"
          : "price_1LsS5NI4HEbZGFYDSZPkd7DS",
        price: 890,
        payd: false,
      },
      modalOpen: false,
      mediumAdvertisingComparator: false,
      fullAdvertisingComparator: false,
      topAdvertisingComparator: false,
      //allDataFetched ?
      allDataFetched: false,
      portalOpen: false,
      portalName: "",
      addAdvertPassed: false,
      advertInDatabase: false,
      arrayOfPaidServices: [],
      //topHandlers
      topAdvertHandlers: [],
      topPayedCount: [],
      nextTopAvailable: false,
      //spinner
      isLoading: false,
      updatedMessage: "Aktualizovat",
      propertyPreviewLink: "",
      facebookPropertyPreviewLink: "https://www.facebook.com/sharer/sharer.php?u=",
    };
  },
  async mounted() {
    // Advert deleted ?
    this.deletedAdvertsArray = await this.deletedAdverts();
    // Stripe getData by currentPropertyId MODULE
    const receipts = await getStripe(this.currentProperty.id);
    if (receipts) {
      let receiptDescriptionArray = [];
      receipts.forEach((receipt) =>
        receiptDescriptionArray.push(receipt.description)
      );
      this.paidServiceHandler(receiptDescriptionArray);
      this.allDataFetched = true;
    }
    // ^^^
    this.topAdvertHandlers = await getTopAdverts(this.currentProperty.id);

    // const testArr = [{ id: true }, { id: true }, { id: true }];
    this.advertArray = await getAdverts(this.currentProperty.id);
    this.generatingUrlLink = true;

    if (this.advertArray.length) {
      this.advertInDatabase = true;
      this.advertArray.map((i) => {
        this.topHandler(i.updatedAt);
      });
    }

    this.topAdvertHandler();

    const normalizeName = this.currentProperty.name
          .normalize("NFD")
          .replace(/\p{Diacritic}/gu, "");
    this.propertyPreviewLink = `/inzerce/${normalizeName}/${this.currentProperty.id}`;
    this.facebookPropertyPreviewLink += `${process.env.VUE_APP_FRONTEND_URL}/inzerce/${normalizeName}/${this.currentProperty.id}`;

    return;
  },
  methods: {
    async deletedAdverts() {
      return await getDeletedAdverts(this.currentProperty.id);
    },
    updateDataHandler(e) {
      this.advertInDatabase = false;
      this.advertArray = e;
      if (!this.advertArray) {
        this.advertInDatabase = false;
      } else {
        return (this.advertInDatabase = true);
      }
    },
    async updatePropertyHandler() {
      this.isLoading = true;
      await updateAdvert(this.currentProperty.id).then((res) => {
        this.isLoading = false;
        res === 200
          ? (this.updatedMessage = "Aktualizováno")
          : (this.updatedMessage = "Zkuste to později");
      });
    },
    openRemoveModalHandler() {
      this.removeModalOpen = true;
    },
    topHandler(date) {
      let dateInput = new Date(date);
      const day = dateInput.setDate(dateInput.getDate() + 1);
      if (new Date(day) > new Date(Date.now())) {
        this.canTopAdvert = false;
      }
      if (new Date(day) < new Date(Date.now())) {
        this.canTopAdvert = true;
      }
    },
    async topEvtHandler() {
      this.topErr = false;
      this.topLoading = true;
      this.topStatArr = [];

      const data = await topAdvert(this.currentProperty.id);

      // Just one bad error to end
      data.forEach((status) => {
        if (status !== 200) {
          this.topLoading = false;
          this.topStatArr.push(status);
          if (status === 477) {
            this.topErrMsg.sreality = "Sreality: Nelze topovat v den vložení!";
          }
          if (status === 478) {
            this.topErrMsg.sreality =
              "Sreality: Nelze topovat duplicitní inzerát!";
          }
          if (status === 479) {
            this.topErrMsg.sreality =
              "Sreality: Nelze topovat nezveřejněný inzerát!";
          }
          if (status === 404) {
            this.topErrMsg.sreality = "Sreality: Inzerát nenalezen!";
          }
          if (status === 400) {
            this.topErrMsg.idnes = "Idnes: Chyba na serveru!";
          }
          this.topErr = true;
        } else {
          this.topLoading = true;
        }
      });
    },

    idnesAdvert() {
      if (this.advertArray.find((i) => i.portal === "Idnes")) {
        return true;
      }
      return false;
    },

    idnesUrl() {
      if (this.advertArray.find((i) => i.portal === "Idnes")) {
        const idnes = this.advertArray.find((i) => i.portal === "Idnes");
        return idnes.advert_url;
      }
    },

    srealityAdvert() {
      if (this.advertArray.find((i) => i.portal === "Sreality")) {
        return true;
      }
      return false;
    },

    srealityUrl() {
      if (this.advertArray.find((i) => i.portal === "Sreality")) {
        const sreality = this.advertArray.find((i) => i.portal === "Sreality");
        return sreality.advert_url + "?preview=1";
      }
    },

    // Add to portal
    async addToPortal() {
      const photos = [];
      await axios
        .get(`${process.env.VUE_APP_STRAPI_API_URL}/user-images?_limit=-1`, {
          headers: {
            Authorization: `Bearer ${this.user.jwt}`,
          },
        })
        .then((res) => {
          res.data.map((image) => {
            if (
              image.property_id === this.$store.state.main.currentProperty.id
            ) {
              photos.push(image);
            }
          });
        });

      if (photos.length < 4) {
        return (this.modalText = "Vaše nemovitost má meně než 4 fotky!");
      }
      if (photos.length > 8) {
        return (this.modalText = "Vaše nemovitost má více než 8 fotek!");
      }

      const noHtmlTags = /(<([^>]+)>)/gi;
      const { propertyDescription } = this.currentProperty;

      if (noHtmlTags.test(propertyDescription)) {
        return (this.modalText =
          "Váš popis nemovitosti obsahuje nepovolené znaky, jako například znaky <, >, /");
      }
      if (propertyDescription.includes("&")) {
        return (this.modalText =
          "Váš popis nemovitosti obsahuje nepovolený znak &");
      }

      this.addAdvertPassed = true;
      this.advertInDatabase = false;
      this.portalOpen = !this.portalOpen;
      await this.sreality();

      this.addAdvertPassed = true;
      this.advertInDatabase = false;
      this.portalOpen = !this.portalOpen;
      return await this.idnes();
    },

    // Sreality
    async sreality() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/properties/sreality/${this.currentProperty.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        );
        if (res.data.addAdvertPassed) {
          this.advertSrealityPass = true;
        }
        this.waitForWriteAdvertUrl();
        return res;
      } catch (e) {
        return e;
      }
    },

    // Idnes
    async idnes() {
      try {
        const res = await axios.get(
          `${process.env.VUE_APP_STRAPI_API_URL}/properties/idnes/${this.currentProperty.id}`,
          {
            headers: {
              Authorization: `Bearer ${this.user.jwt}`,
            },
          }
        );
        if (res.data.passed) {
          this.advertIdnesPass = true;
        }
        this.waitForWriteAdvertUrl();
        return res;
      } catch (e) {
        return e;
      }
    },

    save() {
      try {
        this.$router.push("/pravni-sluzby");
      } catch (error) {
        this.$toast(this.$t("ErrorMessages.ErrorWhileSaving"), {
          className: ["et-alert"],
        });
      }
    },

    async modalOpenHandler() {
      this.modalOpen = !this.modalOpen;
    },

    async portalModal(portalName) {
      this.portalOpen = !this.portalOpen;
      this.portalName = portalName;
    },

    paidServiceHandler(receiptDescriptionArray) {
      receiptDescriptionArray.forEach((item) => {
        if (item === "1x Topování") {
          this.topPayedCount.push(item);
        }
      });
      if (receiptDescriptionArray.includes("1x Rozšířená inzerce")) {
        this.mediumAdvertisingComparator = true;
      } else this.mediumAdvertisingComparator = false;
      if (receiptDescriptionArray.includes("1x Maximální inzerce")) {
        this.fullAdvertisingComparator = true;
      } else this.fullAdvertisingComparator = false;
      if (receiptDescriptionArray.includes("1x Topování")) {
        this.topping.payd = true;
      }
      if (receiptDescriptionArray.includes("1x Základní inzerce")) {
        if (this.deletedAdvertsArray) {
          let baseAdvertisingTicketsCount = [];
          receiptDescriptionArray.forEach((receiptName) => {
            if (receiptName.includes("1x Základní inzerce")) {
              baseAdvertisingTicketsCount.push(receiptName);
            }
          });
          // if tickets count are smaller or equal than deleted adverts, you need pay another base advertising
          if (
            baseAdvertisingTicketsCount.length <=
            this.deletedAdvertsArray.length
          ) {
            this.basicAdvertising.payd = false;
          } else {
            this.basicAdvertising.payd = true;
          }
        } else {
          this.basicAdvertising.payd = true;
        }
      } else this.topping.payd = false;
      this.allDataFetched = true;
    },

    topAdvertHandler() {
      const topCount = this.topAdvertHandlers.length;
      const payedCount = this.topPayedCount.length;
      const topArr = this.topAdvertHandlers;
      if (topCount > 0) {
        if (topCount === 1) {
          if (
            topArr[0].idnesPassed === true &&
            topArr[0].srealityPassed === true
          ) {
            if (payedCount <= topCount) {
              this.topping.payedAgain = true;
            } else {
              this.nextTopAvailable = true;
            }
          }
        }
        if (topCount > 1) {
          if (
            topArr.every((i) => i.idnesPassed === true) &&
            topArr.every((i) => i.srealityPassed === true)
          ) {
            if (payedCount <= topCount) {
              this.topping.payedAgain = true;
            } else {
              this.nextTopAvailable = true;
            }
          }
        }
      }
    },

    async waitForWriteAdvertUrl() {
      this.addAdvertPassed = true;
      this.advertInDatabase = false;
      this.generatingUrlLink = false;
      this.advertArray = {};
      this.workingOn = "Ukládáme váš inzerát do databáze...";

      if (this.advertArray) {
        setTimeout(() => {
          this.workingOn = "Váš inzerát je vkládán na portál...";
        }, 3000);
      }
      setTimeout(() => {
        this.workingOn = "Generuje se odkaz na inzerát...";
      }, 6000);
      setTimeout(async () => {
        this.workingOn = "Váš inzerát je publikován!";
      }, 8000);
      setTimeout(async () => {
        this.advertInDatabase = true;
        this.advertArray = await getAdverts(this.currentProperty.id);
        setTimeout(() => {
          this.generatingUrlLink = true;
        }, 8000);
      }, 10000);
      this.portalOpen = false;
    },
  },
};
</script>

<style scoped>
.create-advertise {
  @apply mr-2 rounded-lg px-5;
  background-color: #1b26e2;
  color: white;
  height: 50px;
}
</style>
