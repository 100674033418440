<template>
  <div>
    <div>
      <div
        class="fixed z-10 inset-0 overflow-y-auto"
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
      >
        <div
          class="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <div
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            aria-hidden="true"
          ></div>
          <span
            class="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
            >&#8203;</span
          >
          <div
            class="text-red-600 inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div>
                <div class="text-left sm:mt-0 sm:ml-4 sm:text-left pb-2">
                  <h3
                    class="text-md leading-6 font-bold select-none pt-2 text-left"
                    id="modal-title"
                  >
                    <p>Přejete si odstranit inzeráty z inzertních portálů?</p>
                  </h3>
                </div>
                <div class="px-4 flex flex-row">
                  Pro řádné odstranění vašich inzerátů opiště kód
                  <div class="font-bold pl-2">{{ this.value }}</div>
                </div>

                <div class="text-left sm:mt-0 sm:ml-4 sm:text-left pb-4">
                  <input
                    v-model="input"
                    type="number"
                    class="border-b-2 border-red-600 focus:outline-none font-bold"
                  />
                </div>

                <div
                  class="text-left sm:mt-0 sm:ml-4 sm:text-left pb-4 font-bold"
                  v-if="err"
                >
                  {{ err }}
                </div>

                <div class="flex flex-row-reverse justify-between">
                  <button
                    @click="$emit('close')"
                    type="button"
                    class="rounded-md border border-transparent shadow-sm px-4 py-1 bg-gray-400 text-base text-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-600 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Zpět
                  </button>
                  <button
                    @click="removeAdvertHandler()"
                    type="button"
                    class="rounded-md border border-transparent shadow-sm px-4 py-1 bg-red-600 text-base text-white hover:bg-red-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-400 sm:ml-3 sm:w-auto sm:text-sm"
                  >
                    Odstranit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { deleteAdvert } from "../../services/rest/advert-services";
export default {
  name: "RemoveAdvertModal",
  props: {
    property: Object,
  },
  data: () => {
    return {
      value: Math.floor(100000 + Math.random() * 900000),
      input: null,
      err: "",
    };
  },
  methods: {
    async removeAdvertHandler() {
      if (this.input == this.value) {
        await deleteAdvert(this.property.id).then((res) => {
          this.$emit("update-data", res);
        });
        this.err = "Vaše inzeráty se odstraňují!";
        setTimeout(() => {
          this.$emit("close");
        }, 3500);
      } else {
        return (this.err = "Špatně opsaný kód!");
      }
    },
  },
};
</script>

<style scoped>
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
